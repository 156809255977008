import React, { useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Anchor from "atom/Anchor";
import Button from "atom/Button";
import PageCenter from "atom/PageCenter";
import ScrollTop from "atom/ScrollTop";
import { Heading } from "atom/Typography";
import Header from "molecule/Header";
import Conversation from "./Conversations";
import Home from "./Home";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

const Screen = () => {
  let location = useLocation().pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <ScrollTop />
      <Header headerText={data.header} links={data.menu} />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route
          path={"/youth-conversations"}
          element={<Conversation version={"youth"} />}
        />
        <Route
          path={"/caregiver-conversations"}
          element={<Conversation version={"caregiver"} />}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <PageCenter
        rootClass={cn(style.visit_root)}
        contentClass={cn(style.visit)}
      >
        <Heading type={"h2"} className={cn(style.text)}>
          {data.visit.label}
        </Heading>
        <Anchor href={data.visit.button.url}>
          <Button>{data.visit.button.label}</Button>
        </Anchor>
      </PageCenter>
      <PageCenter
        rootClass={cn(style.footer_root)}
        contentClass={cn(style.footer)}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data.footer,
          }}
        />
        {location != "/" && (
          <div
            dangerouslySetInnerHTML={{
              __html:
                location == "/caregiver-conversations"
                  ? data.caregiverSources
                  : data.youthSources,
            }}
          />
        )}
        <label>{data.copyright}</label>
      </PageCenter>
    </div>
  );
};

export default Screen;
