export default {
  banner: {
    title: "How to talk about mental health",
    subtitle:
      "Talking about mental health can be a  difficult conversation to have. At the end of the day what really matters is that you show that you’re there to listen and support.",
    image: {
      src: require("assets/banner_home.png"),
      placeholder: require("assets/banner_home_thumb.png"),
      height: 332,
      width: 736,
    },
  },
  talk: {
    title: "Talk about it",
    body: "Discussing mental health is a crucial part of overcoming associated stigmas. Talking about mental illness helps to normalize the topic, and do away with outdated taboos. Individuals who have faced mental health challenges and been able to overcome difficult situations can be a source of inspiration and encouragement for those currently struggling with mental illness.\n\nPay attention to how you’re viewing the issue, and frame it in the same light that you would treat a physical condition — you wouldn’t associate any judgment or shame with going to see a doctor for a regular checkup. Sometimes the hardest part is knowing how to start the conversation. Click one of the buttons below to help guide you in mental health conversations with a teen or individual you are caring for.",
    forTeens: {
      label: "For teens",
    },
    forCaregivers: {
      label: "For caregivers",
    },
  },
  halfhalf: {
    quote: {
      text: "“We think about the ways in which we can get the right information out … ways that we can help families know more about the right solutions and be able to make the best-informed decision because they really are the experts on their child and their family.”",
      image: {
        src: require("assets/doctor.png"),
        height: 144,
        width: 144,
      },
      imageLabel:
        "<strong>Dr. Yusra Benhalim</strong>\nSenior Medical Director,\nOptum Behavioral Health",
      link: {
        label: "Read Dr. Benhalim's article for more insights >",
        pdf: require("assets/optum-youth-mental-health-article.pdf"),
      },
    },
    right: {
      title: "We asked,\nthey answered",
      subtitle: "Building trust and authenticity with teens",
      image: {
        src: require("assets/teen.png"),
        placeholder: require("assets/teen_thumb.png"),
        height: 252,
        width: 490,
      },
      link: {
        label: "View the research summary >",
        pdf: require("assets/optum-youth-mental-health-research-summary.pdf"),
      },
    },
  },
};
