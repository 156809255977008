import React from "react";
import Anchor from "atom/Anchor";
import PageCenter from "atom/PageCenter";
import ProgressiveImage from "atom/ProgressiveImage";
import { Heading } from "atom/Typography";
import Banner from "molecule/Banner";
import Download from "molecule/Download";
import Facts from "molecule/Facts";
import Caregiver from "screen/Caregiver";
import caregiverData from "./caregiverData";
import youthData from "./youthData";
import style from "./style.scss";
import cn from "classnames";

const Conversation = ({ version }) => {
  const data = version == "youth" ? youthData : caregiverData;
  const careVersion = version != "youth" ? true : false;

  return (
    data && (
      <>
        <Banner {...data.banner} reverse={careVersion} key={version} />
        <Facts {...data.facts} />
        <Download {...data.download} warm reverse={careVersion} />
        <PageCenter contentClass={cn(style.article)}>
          <ProgressiveImage {...data.article.image} key={version} />
          <div className={cn(style.imgdiv)} />
          <div className={cn(style.text)}>
            <Heading className={cn(style.title)}>{data.article.title}</Heading>
            <p>{data.article.subtitle}</p>
            <Anchor
              href={
                data.article.link.pdf +
                "?utm_source=optumconversation&utm_medium=weblink&utm_campaign=internal-traffic&utm_id=optumconversation-homepage"
              }
            >
              <Heading className={cn(style.link)} type={"h3"}>
                {data.article.link.label}
              </Heading>
            </Anchor>
          </div>
        </PageCenter>
        <Caregiver />
      </>
    )
  );
};

export default Conversation;
