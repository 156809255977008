import React from "react";
import { NavLink } from "react-router-dom";
import Anchor from "atom/Anchor";
import Button from "atom/Button";
import PageCenter from "atom/PageCenter";
import { Heading } from "atom/Typography";
import Banner from "molecule/Banner";
import HalfHalf from "molecule/HalfHalf";
import Caregiver from "screen/Caregiver";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

const Home = () => {
  return (
    <>
      <Banner {...data.banner} />
      <PageCenter rootClass={cn(style.talk)} contentClass={cn(style.content)}>
        <Heading type={"h2"} className={cn(style.text)} centered>
          {data.talk.title}
        </Heading>
        <div dangerouslySetInnerHTML={{ __html: data.talk.body }} />
        <div className={cn(style.buttons)}>
          <NavLink to={"/youth-conversations"}>
            <Button>{data.talk.forTeens.label}</Button>
          </NavLink>
          <NavLink to={"/caregiver-conversations"}>
            <Button>{data.talk.forCaregivers.label}</Button>
          </NavLink>
        </div>
      </PageCenter>
      <Caregiver />
      <div className={cn(style.hr)} />
      <HalfHalf {...data.halfhalf} />
    </>
  );
};

export default Home;
