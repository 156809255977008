import alert from "./alert.svg";
import arrowcircle from "./arrowcircle.svg";
import brain from "./brain.svg";
import mask from "./mask.svg";
import whistle from "./whistle.svg";

export default {
  alert,
  arrowcircle,
  brain,
  mask,
  whistle,
};
