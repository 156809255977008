export default {
  banner: {
    title: "Talking about mental health with those you care for",
    image: {
      src: require("assets/banner_caregiver.png"),
      placeholder: require("assets/banner_caregiver_thumb.png"),
      height: 336,
      width: 736,
    },
  },
  facts: {
    title: "The numbers tell the story",
    items: [
      {
        icon: "alert",
        label: "$600 billion",
        sublabel:
          "worth of unpaid care is provided by family caregivers annually across the U.S.",
      },
      {
        icon: "brain",
        label: "1 in 6",
        sublabel: "non-caregivers expect to become caregivers within 2 years ",
      },
      {
        icon: "whistle",
        label: "56%",
        sublabel:
          "of caregivers report that the role makes it difficult for them to care for their own mental health",
      },
      {
        icon: "mask",
        label: "1 in 4",
        sublabel: "caregivers find it hard to take care of their own health",
      },
    ],
  },
  download: {
    header: "Caregiver conversation cards",
    title: "Ready to flip the\nconversation?",
    icon: true,
    subtitle:
      "Flip the cards to reflect and take a moment to focus on yourself",
    desktop: {
      label: "Download Digital Cards",
      pdf: require("assets/CaregiverConversationCards_Digital.pdf"),
    },
    mobile: {
      label: "Download Print Cards",
      pdf: require("assets/CaregiverConversationCards_Print.pdf"),
    },
    body: [
      {
        title: "Spark a conversation",
        subtitle:
          "As you’re caring for your loved one, ask them the questions on the orange side of these cards. Invite other friends or family members who may be around into the conversation too. You might be surprised at what you learn about each other.",
      },
      {
        title: "Take a moment to take care of yourself",
        subtitle:
          "Even on the busiest days, it’s important to check in on your own wellbeing. Pause for a moment and reflect with the questions on the blue side of these cards.",
      },
    ],
    image: {
      src: require("assets/conversation.png"),
      height: 380,
      width: 379,
    },
  },
  article: {
    image: {
      src: require("assets/article_caregiver.png"),
      placeholder: require("assets/article_caregiver_thumb.png"),
      height: 416,
      width: 592,
    },
    title: "Caregiving is more than just giving care",
    subtitle:
      "Acting as a caregiver for a loved one is both an incredibly rewarding, and challenging undertaking.\n\nWhile the role can come with many gratifying moments, it’s also often accompanied by stress, fatigue, and its fair share of conflicting emotions. ",
    link: {
      label: "Read article >",
      pdf: require("assets/Combating_Caregiver_Fatigue_Article.pdf"),
    },
  },
};
